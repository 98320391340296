import { axios } from '@/utils/request'

// 列表
// export function getList(pageNo, size, tab) {
//   return axios(({
//     url: '/community/post/list',
//     method: 'get',
//     params: { pageNo: pageNo, size: size, tab: tab }
//   }))
// }

export function getList(params) {
  return axios(({
    url: '/community/post/list',
    method: 'get',
    params: params
  }))
}

// 发布
export function post(topic) {
  return axios({
    url: '/community/post/create',
    method: 'post',
    data: topic
  })
}

// 浏览
export function getTopic(params) {
  return axios({
    url: `/community/post`,
    method: 'get',
    params: params
  })
}

// 浏览
export function getQueryById(params) {
  return axios({
    url: `/community/post/queryById`,
    method: 'get',
    params: params
  })
}

// 获取详情页推荐
export function getRecommendTopics(id) {
  return axios({
    url: '/community/post/recommend',
    method: 'get',
    params: {
      topicId: id
    }
  })
}

export function update(topic) {
  return axios({
    url: '/community/post/update',
    method: 'post',
    data: topic
  })
}

export function deleteTopic(id) {
  return axios({
    url: `/community/post/delete/${id}`,
    method: 'delete'
  })
}

export function deleteComment(parameter) {
  return axios({
    url: `/community/comment/delete_comment`,
    method: 'delete',
    params: parameter
  })
}

export function thumbsUp(parameter) { // 评论点赞
  return axios({
    url: '/community/comment/thumbsUp',
    method: 'put',
    data: parameter
  })
}

export function postThumbsUp(parameter) { // 帖子点赞
  return axios({
    url: '/community/post/thumbsUp',
    method: 'put',
    data: parameter
  })
}
