import { axios } from '@/utils/request'

// 用户主页
export function getInfoByName(username, page, size) {
  return axios({
    // url: '/community/ums/user/' + username,
    url: '/community/umsUser/bmsPost/' + username,
    method: 'get',
    params: {
      pageNo: page,
      size: size
    }
  })
}
// 用户主页
export function getInfo(params) {
  return axios({
    url: '/community/umsUser/info',
    method: 'get',
    params: params
  })
}
// 更新
export function update(user) {
  return axios({
    // url: '/community/ums/user/update',
    url: '/sys/user/edit',
    method: 'put',
    data: user
  })
}

